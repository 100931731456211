<template>
  <div class="assinaturas-isp">
    <h1 class="titulo-card fs-4">Assinaturas</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-1">
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Nova assinatura
          </button>
        </div>

        <div class="input-group mb-1">
          <input
              v-model="search.q"
              v-on:keyup.enter="handlePesquisa(true)"
              type="text"
              class="form-control"
              placeholder="Pesquisar Login ou IP"
              aria-label="Pesquisar Login ou IP"
              aria-describedby="button-addon2">
              <button v-on:click="handlePesquisa(true)" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
          <button v-on:click="handleBtnSwitchFilters()" class="btn btn-outline-secondary dropdown-toggle" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'filter']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div v-if="(search.showFilters)" class="row g-0 justify-content-end mb-3">
        <div class="col-12 col-sm-6 border border-secondary rounded px-2">
          <div class="mb-2">
            <label>Status</label>
            <select v-model="search.status" class="form-select">
              <option value="&status=active">Apenas ativos</option>
              <option value="&status=canceled">Apenas inativos</option>
              <option value="">Todos</option>
            </select>
          </div>
          <div class="mb-2">
            <label>Dia de vencimento</label>
            <select v-model="search.dueDate" class="form-select">
              <option :value="undefined">Todos</option>
              <option v-for="index in 27" :key="index" :value="index">{{ index }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>Login</th>
              <th>Cliente</th>
              <th>Plano</th>
              <th width="130">Status</th>
              <th width="80"></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="assinatura in subscriptions" :key="assinatura.id"
                  @click="navigateToMonthlies(assinatura.id)"
                  :class="classeListaAssinaturas(assinatura)"
                  class="cursor-pointer">
                <td scope="row">
                  {{assinatura.login}}
                  <font-awesome-icon v-if="assinatura.trustUnlock" :icon="['fas', 'unlock']" fixed-width/>
                </td>
                <td>{{nomeCliente(assinatura.customerId) || '-'}}</td>
                <td>{{nomePlano(assinatura.planId)}}</td>
                <td>{{statusAssinatura(assinatura)}}</td>
                <td @click.stop class="text-end">
                  <button v-if="(assinatura.late)" type="button" v-on:click="handleTrustUnlock(assinatura)" class="btn btn-secondary btn-sm me-1">
                    <font-awesome-icon :icon="['fas', 'unlock']" fixed-width/>
                  </button>
                  <button type="button" v-on:click="handleEditaAssinaturaISP(assinatura)" class="btn btn-primary btn-sm me-1">
                    <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                  </button>
                  <!-- <button type="button" v-on:click="handleRemoveAssinaturaISP(assinatura)" class="btn btn-danger btn-sm">
                    <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                  </button> -->
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>

    <!-- modal-edita-assinatura-isp -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-assinatura-isp" id="btn-modal-edita-assinatura-isp-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-edita-assinatura-isp" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!modalAssinaturaISP.data.id" class="modal-title">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
              Inserir nova assinatura
            </h5>
            <h5 v-if="!!modalAssinaturaISP.data.id" class="modal-title">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
              Editando assinatura
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <label>Cliente</label>
                <div class="input-group">
                  <input type="text" disabled v-bind:value="textoClienteSelecionado" class="form-control" placeholder="Selecione o cliente" aria-describedby="button-addon2">
                  <button v-on:click="abreModalPesquisaClientes()" class="btn btn-outline-primary" type="button" id="button-addon2">
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-2">
                <label>Endereço</label>
                <select v-model="modalAssinaturaISP.data.addressId" :disabled="!modalAssinaturaISP.customer.id" class="form-select">
                  <option :value="undefined">Não definido</option>
                  <option v-for="address in modalAssinaturaISP.customer.addresses" :key="address.id" :value="address.id">{{shared.customer.addressReadable(address)}}</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <label>Banco</label>
                <select v-model="modalAssinaturaISP.data.bankId" class="form-select">
                  <option :value="undefined">Nenhum</option>
                  <option v-for="b in banks" :key="b.id" :value="b.id" :class="{'d-none': (b.active == false && b.id !== modalAssinaturaISP.data.bankId)}" :disabled="(!b.active)">{{b.description}}</option>
                </select>
              </div>

              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <label>Usuário</label>
                <input v-model="modalAssinaturaISP.data.login" type="text" class="form-control">
              </div>

              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <label>Senha</label>
                <input v-model="modalAssinaturaISP.data.password" type="text" class="form-control">
              </div>

              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <label>Endereço IPv4</label>
                <input v-model="modalAssinaturaISP.data.fixedIPv4Address" type="text" class="form-control">
              </div>

              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <label>Plano</label>
                <select v-model="modalAssinaturaISP.data.planId" class="form-select">
                  <option v-bind:value="undefined" disabled>Selecione</option>
                  <option v-for="plano in plans" :key="plano.id" v-bind:value="plano.id" v-show="(plano.active || plano.id === modalAssinaturaISP.data.planId)">{{plano.description}}</option>                </select>
              </div>

              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <label>Data contratado</label>
                <input v-model="modalAssinaturaISP.form.subscribedDate" type="date" class="form-control">
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                <label>Dia de vencimento</label>
                <select v-model.number="modalAssinaturaISP.data.dueDay" class="form-select">
                  <option disabled :value="0">Selecione</option>
                  <option :value="1">1</option>
                  <option :value="2">2</option>
                  <option :value="3">3</option>
                  <option :value="4">4</option>
                  <option :value="5">5</option>
                  <option :value="6">6</option>
                  <option :value="7">7</option>
                  <option :value="8">8</option>
                  <option :value="9">9</option>
                  <option :value="10">10</option>
                  <option :value="11">11</option>
                  <option :value="12">12</option>
                  <option :value="13">13</option>
                  <option :value="14">14</option>
                  <option :value="15">15</option>
                  <option :value="16">16</option>
                  <option :value="17">17</option>
                  <option :value="18">18</option>
                  <option :value="19">19</option>
                  <option :value="20">20</option>
                  <option :value="21">21</option>
                  <option :value="22">22</option>
                  <option :value="23">23</option>
                  <option :value="24">24</option>
                  <option :value="25">25</option>
                  <option :value="26">26</option>
                  <option :value="27">27</option>
                </select>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                <label>Dias para corte</label>
                <input v-model.number="modalAssinaturaISP.data.dueDays" type="number" class="form-control">
              </div>

              
              <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                <label>Cobrança</label>
                <select disabled v-model="modalAssinaturaISP.data.isento" class="form-select">
                  <option v-bind:value="false">Normal (não isento)</option>
                  <option v-bind:value="true">Isento</option>
                </select>
              </div>

              

              <div class="col-12 col-sm-12 col-md-4 mb-2">
                <label>Cobrança automática</label>
                <div class="form-check form-switch">
                  <input disabled v-model="modalAssinaturaISP.data.cobranca_automatica" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                  <label class="form-check-label" for="flexSwitchCheckDefault">Habilitar</label>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <label>Quantas cobranças por vez</label>
                <input v-model.number="modalAssinaturaISP.data.cobranca_automatica_por_vez"
                    v-bind:disabled="(!modalAssinaturaISP.data.cobranca_automatica)"
                    type="number"
                    value="1"
                    min="1"
                    max="12"
                    class="form-control">
              </div>

              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <label>Quantos dias antes de vencer</label>
                <input v-model="modalAssinaturaISP.data.cobranca_automatica_dias_antes_de_vencer"
                    v-bind:disabled="(!modalAssinaturaISP.data.cobranca_automatica)"
                    type="number"
                    value="30"
                    min="1"
                    max="365"
                    class="form-control">
              </div>

              <div class="col-12">
                <label>Observação</label>
                <textarea disabled v-model="modalAssinaturaISP.data.observacao" class="form-control"></textarea>
              </div> -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-edita-assinatura-isp-fecha">Fechar</button>
            <button type="button"
                v-if="!modalAssinaturaISP.data.id"
                v-on:click="handleModalAssinaturaISPCadastrar()"
                v-bind:disabled="!podeSalvarAssinaturaISP()"
                class="btn btn-success">
              Cadastrar
            </button>
            <button type="button"
                v-if="!!modalAssinaturaISP.data.id"
                v-on:click="handleModalAssinaturaISPSalvar()"
                v-bind:disabled="!podeSalvarAssinaturaISP()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-edita-assinatura-isp -->

    <!-- modal-trust-unlock -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-trust-unlock" id="btn-modal-trust-unlock-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-trust-unlock" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'unlock']" fixed-width/>
              Desbloqueio de confiança
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-2">
                <label>Status</label>
                <div class="form-check">
                  <label class="form-check-label cursor-pointer">
                    <input v-model="modalTrustUnlock.form.active" type="checkbox" class="form-check-input cursor-pointer">
                    Ativo
                  </label>
                </div>
              </div>
              <div class="col-12 mb-2">
                <label>Até</label>
                <input v-model="modalTrustUnlock.form.trustUnlockUntil" :disabled="(!modalTrustUnlock.form.active)" type="date" class="form-control">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-trust-unlock-fecha">Fechar</button>
            <button type="button"
                v-on:click="modalTrustUnlockHandleSave()"
                v-bind:disabled="!modalTrustUnlockCanSave()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-trust-unlock -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import date from '@/modules/date'
import godate from '../modules/godate'
import shared from '../modules/shared'
import model from '@/model'
import pesquisaCliente from '@/modules/pesquisa-cliente'

export default {
  name: 'AssinaturasISP',

  components: {
    Paginador,
  },

  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      search: {
        q: '',
        showFilters: false,
        status: '&status=active',
        dueDate: undefined,
      },
      subscriptionConfig: {
        dueDays: 0,
      },
      plans: [],
      banks: [],
      subscriptions: [],
      customers: [],
      listaEnderecos: [],
      modalAssinaturaISP: {
        customer: {addresses: []},
        listaEnderecos: [],
        data: {
          id: undefined,
          customerId: undefined,
          addressId: undefined,
          planId: undefined,
          subscribedDate: '',
          login: '',
          password: '',
          fixedIPv4Address: '',
          status: 'active',
          dueDay: 0,
        },
        form: {
          subscribedDate: undefined,
        },
      },
      modalTrustUnlock: {
        subscription: undefined,
        form: {
          active: false,
          trustUnlockUntil: undefined,
        },
      },
    }
  },

  methods: {
    loadConfig() {
      api.get(`/configs/subscription`).then(res => {
        this.subscriptionConfig.dueDays = res.data.dueDays;
      }).catch(() => {
        setTimeout(() => {
          this.loadConfig();
        }, 1000);
      });
    },

    carregaListaPlanos() {
      api.get(`/isp/plans?paginate.cancel`).then(res => {
        this.plans = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaListaPlanos();
        }, 1000);
      });
    },

    loadBanks() {
      api.get(`/banks?paginate.cancel&orderBy[description]`).then(res => {
        this.banks = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.loadBanks();
        }, 1000);
      });
    },

    handlePesquisa(resetPage) {
      const q = this.search.q;
      const status = this.search.status;
      let params = this.paginador.requestParams;
      if (resetPage) {
        params = this.paginador.requestParamsFirst;
      }

      let apiUrl = `/isp/subscriptions?${params}&q=${this.search.q}&orderBy[login]=asc&q=${q}${status}`
      if (this.search.dueDate) {
        apiUrl += `&due_day=${this.search.dueDate}`;
      }

      loading(true);
      api.get(apiUrl).then(res => {
        loading(false);
        this.subscriptions = res.data.items;
        this.customers = res.data.aggregators.customers;
        this.listaEnderecos = res.data.enderecos || [];
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleBtnSwitchFilters() {
      this.search.showFilters = !this.search.showFilters;
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    resetModalAssinaturaISP() {
      this.modalAssinaturaISP.customer = {addresses: []};
      this.modalAssinaturaISP.data.id = undefined;
      this.modalAssinaturaISP.data.customerId = undefined;
      this.modalAssinaturaISP.data.addressId = undefined;
      this.modalAssinaturaISP.data.planId = undefined;
      this.modalAssinaturaISP.data.subscribedDate = undefined;
      this.modalAssinaturaISP.data.dueDay = 0;
      this.modalAssinaturaISP.data.login = '';
      this.modalAssinaturaISP.data.password = '';
      this.modalAssinaturaISP.data.fixedIPv4Address = '';
      this.modalAssinaturaISP.data.status = 'active';

      this.modalAssinaturaISP.data.dueDays = this.subscriptionConfig.dueDays;

      this.modalAssinaturaISP.form.subscribedDate = shared.date.todayMysql();
    },

    handleAdicionar() {
      this.abreModalCadastroAssinaturaISP();
    },

    navigateToMonthlies(subscriptionID) {
      this.$router.push(`/assinaturas-isp/${subscriptionID}/mensalidades`);
    },

    async handleEditaAssinaturaISP(assinaturaISP) {
      this.resetModalAssinaturaISP();
      this.modalAssinaturaISP.data = clone(assinaturaISP);
      this.modalAssinaturaISP.form.subscribedDate = date.mysqlFromGo(assinaturaISP.subscribedDate)
      document.getElementById('btn-modal-edita-assinatura-isp-abre').click();

      await pesquisaCliente(assinaturaISP.customerId).then(res => {
        this.modalAssinaturaISP.customer = clone(res);
      }).catch(() => {
        dialogo.alerta('Não foi possível localizar o cliente');
      });
    },

    handleTrustUnlock(subscription) {
      this.modalTrustUnlock.subscription = subscription;
      this.modalTrustUnlock.form.active = subscription.trustUnlock;
      this.modalTrustUnlock.form.trustUnlockUntil = undefined;
      if (subscription.trustUnlockUntil) {
        this.modalTrustUnlock.form.trustUnlockUntil = godate.toMysqlUTC(subscription.trustUnlockUntil);
      }

      document.getElementById('btn-modal-trust-unlock-abre').click();
    },
    modalTrustUnlockCanSave() {
      const now = new Date();
      const until = date.fromMysql(this.modalTrustUnlock.form.trustUnlockUntil);

      switch (true) {
        case (this.modalTrustUnlock.form.active && !this.modalTrustUnlock.form.trustUnlockUntil):
        case (date.datePart(now) > until):
          return false;
      }

      return true;
    },
    modalTrustUnlockHandleSave() {
      let active = this.modalTrustUnlock.form.active;
      let until = active ? date.fromMysql(this.modalTrustUnlock.form.trustUnlockUntil) : undefined;

      const id = this.modalTrustUnlock.subscription.id;
      const data = {
        trustUnlock: active,
        trustUnlockUntil: until,
      };

      loading(true);
      api.patch(`/isp/subscriptions/${id}`, data).then(res => {
        this.modalTrustUnlock.subscription.trustUnlock = res.data.trustUnlock;
        this.modalTrustUnlock.subscription.trustUnlockUntil = res.data.trustUnlockUntil;
        loading(false);
      document.getElementById('btn-modal-trust-unlock-fecha').click();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },


    // handleRemoveAssinaturaISP(assinatura) {
    //   dialogo.confirmacao(`Confirma remover a assinatura do cliente <b>${this.nomeCliente(assinatura.customerId)}</b>?`).then(() => {
    //     loading(true);

    //     api.delete(`/isp/subscriptions/${assinatura.id}`).then(() => {
    //       loading(false);

    //       this.handlePesquisa();
    //     }).catch(error => {
    //       loading(false);
    //       dialogo.erroHttp(error);
    //     });
    //   });
    // },

    abreModalCadastroAssinaturaISP() {
      this.resetModalAssinaturaISP();
      document.getElementById('btn-modal-edita-assinatura-isp-abre').click();
    },

    abreModalPesquisaClientes() {
      document.getElementById('btn-modal-edita-assinatura-isp-fecha').click();
      pesquisaCliente().then(res => {
        document.getElementById('btn-modal-edita-assinatura-isp-abre').click();
        this.modalAssinaturaISP.customer = clone(res);
        this.modalAssinaturaISP.data.customerId = res.id;
      }).catch(() => {
        document.getElementById('btn-modal-edita-assinatura-isp-abre').click();
      });
    },

    podeSalvarAssinaturaISP() {
      switch (true) {
        case (!this.modalAssinaturaISP.data.customerId):
        case (!this.modalAssinaturaISP.data.planId):
        case (!this.modalAssinaturaISP.data.dueDay):
        case (this.modalAssinaturaISP.data.login.length < 3):
        case (this.modalAssinaturaISP.data.password.length < 3):
        case (!this.modalAssinaturaISP.form.subscribedDate):
        case (!this.modalAssinaturaISP.data.dueDays):
        case (this.modalAssinaturaISP.data.dueDays < 0):
        // case (!this.modalAssinaturaISP.data.addressId):
        // case (!this.modalAssinaturaISP.data.endereco_id):
        // case (this.modalAssinaturaISP.data.dia_vencimento < 1):
        // case (this.modalAssinaturaISP.data.dia_vencimento > 28):
        // case (this.modalAssinaturaISP.data.cobranca_automatica_por_vez < 1):
        // case (this.modalAssinaturaISP.data.cobranca_automatica_por_vez > 12):
        // case (this.modalAssinaturaISP.data.cobranca_automatica_dias_antes_de_vencer < 1):
        // case (this.modalAssinaturaISP.data.cobranca_automatica_dias_antes_de_vencer > 365):
          return false;

        default:
          return true;
      }
    },

    handleModalAssinaturaISPCadastrar() {
      const sendData = clone(this.modalAssinaturaISP.data);
      sendData.subscribedDate = godate.fromMysql(this.modalAssinaturaISP.form.subscribedDate);

      loading(true);
      api.post('/isp/subscriptions', sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-assinatura-isp-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleModalAssinaturaISPSalvar() {
      const sendData = clone(this.modalAssinaturaISP.data);
      sendData.subscribedDate = godate.fromMysql(this.modalAssinaturaISP.form.subscribedDate);

      loading(true);
      api.put(`/isp/subscriptions`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-assinatura-isp-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    nomePlano(planoID) {
      const plano = this.plans.find(x => planoID === x.id);

      if (!plano) {
        return '';
      }

      return plano.description;
    },

    nomeCliente(clienteID) {
      const cliente = this.customers.find(x => clienteID === x.id);

      if (!cliente) {
        return '';
      }

      return cliente.name;
    },

    enderecoAssinatura(enderecoID) {
      const endereco = this.listaEnderecos.find(x => enderecoID === x.id);

      if (!endereco) {
        return '';
      }

      return shared.customer.addressReadable(endereco);
    },

    statusAssinaturaEnum(assinatura) {
      switch (true) {
        case assinatura.status == 'canceled':
          return assinatura.status;

        case assinatura.late:
          return 'late';

        case assinatura.suspended:
          return 'suspended';

        case assinatura.status == 'active':
          return assinatura.status;

        default:
          return 'unknown';
      }
    },

    statusAssinatura(assinatura) {
      const status = this.statusAssinaturaEnum(assinatura);

      switch (status) {
        case 'canceled': 
          return 'Cancelado';

        case 'active':
          return 'Ativo';

        case 'late':
          return 'Atrasado';

        case 'suspended':
          return 'Suspensão manual';

        default:
          return status;
      }
    },

    classeListaAssinaturas(assinatura) {
      const status = this.statusAssinaturaEnum(assinatura);

      switch (status) {
        case 'canceled': 
          return 'text-muted-2';

        case 'active':
          return 'text-info';

        case 'late':
          return 'text-warning';

        case 'suspended':
          return 'text-danger';

        default:
          return status;
      }
    },
  },

  computed: {
    textoClienteSelecionado() {
      if (!this.modalAssinaturaISP.customer) {
        return '';
      }

      return this.modalAssinaturaISP.customer.name;
    },
  
    shared() {
      return shared;
    },
  },

  created() {
    this.loadConfig();
    this.carregaListaPlanos();
    this.handlePesquisa();
    this.loadBanks();
  }
}
</script>
